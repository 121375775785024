* {margin: 0;padding: 0;box-sizing: border-box;}
body {float: left;width: 100%;box-sizing: border-box;position: relative;overflow: hidden;}
.wrapper {float: left;width: 100%;overflow: hidden;}
/* dashboard color change var */
:root {
    --mainBackground: #e3e8f2;
    --loginColor: #2c59c0;
    --primary-rgb:44, 89, 192;
    --maincolor: #0e39b6;
    --maincolortwo: #008fff;
    --sidebar-width: 240px;
    --layout-gap:0px;
    --bs-font-size:14px
}

/* login css start here */
.mainloginwrps {float: left;width: 100%;height: 100vh;display: flex;justify-content: center;background-color: var(--mainBackground);align-items: center;}
.login_wrapper {
  float: left;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.login_wrapper h5 {
  font-size: 45px;
  font-weight: 600;
  color: rgb(0, 157, 255);
  text-shadow: -1px 1px 2px #0087ff, -1px 1px 52px #0087ff;
}
.login_wrap_slider {float: left;width: 100%;background-color: var(--loginColor);padding: 55px 15px;display: flex;justify-content: center;align-items: center;flex-basis: 35%;flex-direction: column;}
.login_wrap_slider img {width: 100%;height: 180px;object-fit: contain;object-position: center;}
.login_wrap_slider h4 {float: left;width: 100%;text-align: center;color: #fff;margin: 25px 0 9px;font-weight: 600;letter-spacing: 0.6px;}
.loginTEXTS h4 {margin: 0px;float: left;width: 100%;font-size: 24px;text-align: left;}
.login_wrap_slider p {color: #dbe5ff;text-align: center;font-size: 14px;margin: 0 0 0;float: left;width: 75%;}
.login_form_wraps {
  float: left;
  width: 410px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 34%;
  padding: 25px 25px;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0px 0px 31px -11px #ccc;
}
.loginTEXTS {float: left;width: 100%;text-align: center;margin: 0 0 15px;position: relative;}
.loginTEXTS h1 {float: left;width: 100%;margin-bottom: 17px;}
.form-group {float: left;width: 100%;margin-bottom: 15px;}
.form-group label {float: left;width: 100%;font-weight: 400;font-size: 14px;margin: 0 0 5px;}
.forgot_password_link {padding: 6px 0px;border-radius: 8px;line-height: 25px;position: relative;float: right;font-size: 14px;outline: none !important;border: 0px !important;}
.formInput {float: left;width: 100%;position: relative;}
.formInput input {height: 40px;border-radius: 8px;padding: 7px 12px;font-size: 13px;box-shadow: none !important;}
.errormendatorr {float: left;width: 100%;font-size: 13px;color: red;margin: 5px 0 0;}
.errormendatorr i {margin-right: 4px;}
.eyeshowbtn {border: 0px;background: transparent;position: absolute;right: 10px;top: 0px;line-height: normal;height: 100%;display: flex;align-items: center;justify-content: flex-end;}
.form-group-btn {float: left;width: 100%;margin: 5px 0 0;}
.loginCmn_btn {background-color: var(--loginColor) !important;border-color: var(--loginColor) !important;color: #fff !important;padding: 6px 22px;border-radius: 8px;line-height: 25px;position: relative;}
.loginCmn_btn:hover {color: var(--maincolortwo);}
/* login css end here */

/* admin css start here */
.adminmain {display: flex;height: 100vh;justify-content: flex-start;column-gap: var(--layout-gap);align-items: center;}
.adminmain {float: left;position: relative;width: 100%;background-color: var(--mainBackground);}
.dashboard_wrapper {float: left;position: relative;width: 100%;height: 100%;}
.dashboard_wrapper_form {float: left;position: relative;width: 100%;height: calc(100vh - 152px);}
.sidebar {background-color: #fff;flex-basis: var(--sidebar-width);float: left;height: 100vh;width: 100%;padding: 8px 10px;}
.navbarHeader {border-bottom: 1px solid #008fff;float: left;height: 71px;padding: 22px 25px;width: 100%;}
.flex-column.nav {width: 100%;}
.flex-column .nav-link {width: 100%;padding: 9px 10px;margin: 0 0 10px;display: flex;flex-direction: row;text-align: center;align-items: center;font-size: 12px;font-weight: 500;line-height: normal;border-radius: var(--bs-border-radius)!important;position: relative;transition: .5s;}
.flex-column .nav-link:hover {background-color: #f3f7ff;}
.flex-column .nav-link{--nav-active-link-color: #0b0000;--nav-active-link-bg:var(--loginColor);--nav-link-icon:var(--loginColor);--nav-link-active-icon:var(--loginColor);}
.flex-column .nav-link.primary{--nav-active-link-color: #fff;--nav-active-link-bg:var(--loginColor);--nav-link-active-icon:#fff;}
.flex-column .nav-link.secondary{--nav-active-link-bg:#f3f7ff;}
.flex-column .nav-link.active span {color: var(--nav-active-link-color);}
.flex-column .nav-link.active {transition: .5s;background-color: var(--nav-active-link-bg) !important;color: var(--nav-active-link-color);}
.flex-column .nav-link.active img {-webkit-filter: invert(91%) sepia(67%) saturate(1438%) hue-rotate(181deg) brightness(111113%) contrast(11115%);filter: invert(91%) sepia(67%) saturate(1438%) hue-rotate(181deg) brightness(111113%) contrast(11115%);}
.flex-column .nav-link.active i {color: var(--nav-link-active-icon); --bg-icon:var(--nav-active-link-color)}
.flex-column .nav-link i {font-size: 21px;color: var(--loginColor);line-height: 24px;}
.flex-column .nav-link img {width: 25px;height: 25px;object-fit: contain;margin: 0 0 6px;}
.flex-column .nav-link i {--bg-icon:var(--nav-link-icon)}
.flex-column .nav-link span {color: #0b0000;line-height: 12px;font-weight: 400;margin-left: 15px;}
.panelwrapper {float: left;width: calc(100% - calc(var(--sidebar-width) + var(--layout-gap)));height: calc(100vh);}
.head_section {float: left;width: 100%;padding: 10px 15px 10px 15px;background-color: #fff;margin: 0 0 0px;position: relative;box-sizing: border-box;min-height: 55px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #e3e8f2;}
.head_section h4 {float: left;width: auto;font-size: 16px;margin: 0px;position: relative;text-transform: capitalize;flex: 1;}
.panelContentwrapper {float: left;width: 100%;height: calc(100vh - 67px);padding: 10px 10px;background-color: transparent;position: relative;}
.panelContentwrapper .panel_children{background-color: white;padding: 15px 15px ;background-color: white;height: 100%;width: 100%;position: relative;}
.sidebar .logo-title{font-size: 30px;font-weight: 600;color: rgb(0, 157, 255);text-shadow: -1px 1px 2px #0087ff, -1px 1px 52px #0087ff;}
/* admin css end here */
/* header css start here */
.dropdownwraps {float: right;width: auto;display: flex;align-items: center;justify-content: flex-end;height: 100%;}
.user_DETAILS {float: left;width: auto;height: 100%;background-color: #2c59c0;display: flex;align-items: center;justify-content: center;padding: 5px 16px;color: #fff;cursor: pointer;column-gap: 8px;}
.user_DETAILS i {font-size: 20px;}
.add_cartbtn {float: left;width: auto;height: 100%;border: 0;background: transparent;padding: 0px 20px 0px 5px;position: relative;font-size: 25px;margin-right: 10px;}
.add_cartbtn .badge {border-radius: 100%;font-size: 11px;font-weight: 500;position: absolute;right: 8px;top: 3px;line-height: normal;background-color: #ff200c !important;box-sizing: border-box;}
.logoutbtn, .logoutbtn:focus, .logoutbtn:active {padding: 0px 10px;font-size: 22px;height: 100%;background-color: #ff200c !important;border: 0px;border-radius: 0px;color: #fff !important;outline: none !important;}
.logoutbtn:hover {background-color: #00be00 !important;}
.header_back {float: left;width: 100%;border-bottom: 1px solid #2c59c0;margin-bottom: 20px;position: relative;}
.header_backbtn {float: right;width: auto;font-size: 16px;line-height: normal;padding: 0px 8px 7px;box-sizing: border-box;border-radius: 11px;cursor: pointer;text-decoration: none;}
.header_backbtn i {line-height: normal;float: left;width: auto;}
.user_DETAILS .avatrshow {width: 27px;}
.user_DETAILS .avatrshow img {width: 100%;height: 100%;object-fit: cover;object-position: center;}
/* header css end here */

/* table filter */
.card-header-New {float: left;margin: 0 0 15px;width: 100%;}
.card-header-New select {height: 36px !important;border-radius: 0px !important;}
.searchBoxwithbtn {float: left;position: relative;width: 260px;}
.searchBoxwithbtn .form-control {border: 1px solid #ddd;border-radius: 0;box-shadow: none!important;font-size: 13px;height: 36px;padding: 7px 35px 7px 10px;}
.searchBoxwithbtn .icon-tabler-search {position: absolute;right: 9px;top: 6px;width: 19px;}
.card-header-right {float: right;width: auto;}
.activestatusselet, .userLimitselect {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 90px;}
.card-header-right select:first-child {margin-right: 10px;}
.card-header-right select {box-shadow: none!important;}
.head_cmn_btn, .head_cmn_btn:focus, .head_cmn_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: var(--loginColor) !important;color: #fff !important;border-color: var(--loginColor) !important;}
.head_cmn_btn:hover {background-color: var(--maincolor) !important;}
.cmn_export_btn, .cmn_export_btn:focus, .cmn_export_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: #00b13a !important;color: #fff !important;border-color: #00b13a !important;}
.cmn_export_btn:hover {background-color: #008d21 !important;}
.cmn_export_btn img {width: 18px;line-height: normal;margin: 0px 3px 0px 0px;}
.head_cmn_btn i {margin: 0 4px 0 0px;}
.searchBoxwithbtn .searchclear {float: right;width: auto;position: absolute;top: 9px;right: 9px;font-size: 13px;cursor: pointer;}
/* table filter */

/* table css */
.tableContent {float: left;width: 100%;position: relative;}
.panelContentwrapper .table {border: 1px solid #eee;border-radius: 6px;}
.panelContentwrapper .table td .form-group {margin-bottom: 0px;}
.panelContentwrapper .table thead, .panelContentwrapper .table tr, tbody, td, tfoot, th, thead, tr {float: none;width: auto;}
.table-responsive{overflow-x:auto;-webkit-overflow-scrolling:touch}
.panelContentwrapper .table thead th {background-color: #eee;font-size: 13px;padding: 12px 12px;font-weight: 500;}
.panelContentwrapper .table tbody td {font-size: 13px;padding: 9px 12px;vertical-align: middle;background-color: transparent;}
.panelContentwrapper .table tbody tr:hover, .panelContentwrapper .table tr.active {background-color: #F3F3F9;}
.tablecollapsewraps {background-color: transparent !important;}
.tablecollapsewraps table th {background-color: #f8f8f8;font-size: 12px;padding: 10px 12px;font-weight: 500;}
.tablecollapsewraps table td {font-size: 12px;}
.actionbtn {float: left;width: 17px;height: 17px;padding: 0px;border: 0px;outline: none !important;box-shadow: none;background: transparent;-webkit-transition: 0.2s;-moz-transition: 0.2s;-ms-transition: 0.2s;-o-transition: 0.2s;transition: 0.2s;}
.actionbtn img {float: left;height: 100%;width: 100%;}
.actionbtn.active img {transform: rotate(180deg);-webkit-transition: 0.2s;-moz-transition: 0.2s;-ms-transition: 0.2s;-o-transition: 0.2s;transition: 0.2s;}
.panelContentwrapper .table tbody .actionbtn {margin-right: 12px;}
.panelContentwrapper .table tbody .actionbtn:last-child {margin-right: 0px;}
.panelContentwrapper .table tbody .actiontblebtn:last-child {margin-right: 0px;}
.actiontblebtn {padding: 0px;font-size: 17px;background: transparent;outline: none !important;border: 0px !important;line-height: normal;float: left;width: auto;margin-right: 11px;}
.actiontblebtn img {width: 16px;}
.tooltip.show {position: fixed !important;}
/* table css */

/* common toggle css */
.comnToggle {float: left;width: auto;}
.comnToggle i {font-size: 25px;cursor: pointer;line-height: normal;}
.comnToggle .bi-toggle2-on {color: var(--maincolortwo);}
/* common toggle css */

/* dahsboard css start here */
.column_count_colus {float: left;width: 100%;display: flex;column-gap: 15px;flex-wrap: wrap;}
.column_count_colus .dashColswe {float: left;flex-basis: 24%;border: 1px solid #ddd;margin: 0 0 20px;padding: 20px 20px;text-align: center;}
.column_count_colus .dashColswe .dashIcon {float: none;width: 60px;height: 60px;display: flex;align-items: center;justify-content: center;margin: 0 auto;font-size: 20px;border-radius: 100%;}
.dashColswe h1 {font-size: 40px;font-weight: 700;margin: 3px 0 5px;}
.dashColswe h4 {font-size: 17px;margin: 0px;color: #959595;}
.column_count_colus .dashColswe .dashIcon img {width: 26px;}
/* dahsboard css end here */

/* product css start here */
.panelContentwrapper .table tbody td .avatrshow {float: left;width: 65px;}
.panelContentwrapper .table tbody td .avatrshow img {width: 100%;}
/* product css end here */

/* select start here */
select.form-control {height: 40px;border-radius: 8px;padding: 7px 12px;font-size: 13px;box-shadow: none !important;background-image: url("../img/careticons.png");background-repeat: no-repeat;background-position: 96% center;background-size: 12px;}
textarea.form-control {height: 100px;border-radius: 8px;padding: 10px 12px;font-size: 13px;box-shadow: none !important;resize: none;}
.filedaddwraps {float: left;width: 100%;display: flex;align-items: center;justify-content: center;position: relative;column-gap: 10px;}
.filedaddwraps .addbtn {background-color: #2c59c0;color: #fff;padding: 0px 5px;border-radius: 8px;font-size: 27px;line-height: normal;}
.dimession_wraps {float: left;width: 100%;display: flex;align-items: center;justify-content: center;column-gap: 10px;}
.dimession_wraps input {height: 40px;border-radius: 8px;padding: 7px 12px;font-size: 13px;box-shadow: none !important;}
.addbtncmn, .addbtncmn:focus, .addbtncmn:active {background-color: var(--loginColor) !important;color: #fff !important;padding: 7px 25px;font-size: 15px;border-radius: 8px;border-color: var(--loginColor) !important;}
.addbtncmn:hover {background-color: var(--maincolortwo) !important;border-color: var(--maincolortwo) !important;}
/* select end here */
.scrollformCustom {float: left;width: 100%;height: calc(100vh - 150px);}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {  -webkit-appearance: none;  margin: 0;}
/* Firefox */
input[type=number] {-moz-appearance: textfield;}
/* country code start here */
.selectNoInputField {float: left;width: 100%;border: 1px solid #E0E0E5;border-radius: 8px;position: relative;display: flex;}
.selectNoInputField input {height: 40px;border: 0px;padding: 10px 10px;font-size: 13px;outline: none;width: 100%;}
.selectNoInputField .inputBoxSelect {width: 100px;text-align: left;margin: 0px;position: relative;padding-left: 10px;}
.selectNoInputField .inputBoxSelect::after{content: "";float: left;width: 1px;height: 20px;background-color: #c1c1c1;position: absolute;right: 0;top: 11px;}
.form-group.error .selectNoInputField {border-color: #F84646;}
.form-group.error .form-groupfiled.expirydatswrapper .SiXSY, .form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {border-color: #F84646 !important;}
.countselet {font-size: 12px;float: left;width: auto;margin: 0 1px 0 0;color: #333;}
.flagsDropdown {float: left;width: 220px;margin: 0px;padding: 0px;position: absolute !important;z-index: 9;background-color: #fff;box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);left: 0;bottom: 51px;top: auto;border: 0px;}
.flagsDropdownMain a span {float: left;width: 80%;text-overflow: ellipsis;overflow: hidden;}
.flagsDropdownMain {float: left;width: 100%;padding: 0px;margin: 0px;max-height: 150px;overflow: auto;}
.flagsDropdownMain a {float: left;width: 100%;list-style: none;display: flex;padding: 10px 13px;font-size: 12px;cursor: pointer;text-align: left;vertical-align: middle;align-items: center;}
.flagsDropdownMain a:hover {background-color: #eee;}
.flagsDropdownMain a img {width: 26px;margin: 0px 8px 0px 0px;}
.selectNoInputField .inputBoxSelect .selectEmojis { padding: 0px; border: 0px; height: 100%; width: 100%; display: flex; position: relative; align-items: center; text-align: center; justify-content: flex-start; background-color: transparent; margin: 0px; }
.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {width: 20px;height: 20px;border-radius: 100%;margin: 0px 7px 0px 0px;object-fit: cover;}
.noteemail {color: #9c9c9c;font-size: 15px;}
.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {width: 7px;margin: 0px 0px 0px 3px;}
.btn.formcomnbtn {background-color: #1D1D1D;border-color: #1D1D1D;width: 100%;padding: 11px 45px;float: right;font-size: 16px;color: #cecece;border-radius: 0;}
/* country code end here */
.addtocartebtn, .addtocartebtn:focus, .addtocartebtn:active { background-color: var(--loginColor) !important; border-color: var(--loginColor) !important; color: #fff !important; font-size: 13px; border-radius: 30px; padding: 4px 8px; }
.addtocartebtn:hover {background-color: var(--maincolortwo) !important;border-color: var(--maincolortwo) !important;}
.btn.btn-clsoe {padding: 0px;color: red;font-size: 19px;outline: none !important;box-shadow: none !important;border: 0px !important;}
.EmailMarginWrapper {float: left;width: 100%;position: relative;}
.EmailMarginWrapper.active {margin: 15px 0 0;}
.EmailMarginWrapper .form-group {margin-bottom: 12px;}
/* cart css start here */
.cart_wrappperr {float: left;width: 100%;height: calc(100vh - 150px);padding: 0px;margin: 0 0 10px;position: relative;}
.cart_wrappperr .ScrollbarsWrapper .view {padding-right: 15px !important;padding-bottom: 50px;}
.cart_wrappperr li {float: left;width: 100%;position: relative;margin-bottom: 25px;list-style: none;}
.cart_wrappperr li .avatrshow {float: left;width: 100px;height: 100px;margin-right: 15px;background-color: #eee;}
.cart_wrappperr li .avatrshow img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.cart_wrappperr li .cartdetails {float: left;width: 70%;}
.cart_wrappperr li .cartdetails h4 {float: left;width: 100%;text-transform: capitalize;font-size: 18px;font-weight: 700;margin: 0 0 6px;}
.cart_wrappperr li .cartdetails p {float: left;width: 100%;margin-bottom: 5px;font-size: 14px;}
.cart_wrappperr li .cartbtn_wraps {float: right;width: 12%;text-align: center;position: relative;}
.cart_wrappperr li .cartbtn_wraps .quantity_product {float: left;width: 100%;padding: 10px 10px 6px;border: 1px solid #2c59c0;border-radius: 6px;font-size: 17px;position: relative;margin: 10px 0 8px;}
ul.size_product {float: left;}
.cart_wrappperr li .cartdetails .size_product {float: left;width: auto;padding: 0px;}
.cart_wrappperr li .cartdetails .size_product li {margin: 0 0 5px;font-size: 13px;}
.cart_wrappperr li .cartdetails .size_product li label {font-weight: 500;}
.cart_wrappperr li .cartbtn_wraps .quantity_product label {float: none;width: auto;background-color: #fff;position: absolute;top: -11px;font-size: 14px;left: 0;right: 0;display: table;margin: 0 auto;padding: 0 10px;color: #2c59c0;font-weight: 500;}
.cart_wrappperr li .cartbtn_wraps h2{font-size: 28px;font-weight: 700;float: left;width: 100%;}
.productcartList {border-bottom: 1px solid #ddd;padding-bottom: 15px;}
.cart_btn_group {float: left;width: 100%;margin: 10px 0 0;}
.removercart {color: #f40000;font-size: 14px;padding: 0px;border: 0px;background: transparent;}
.main_cart_wrapper {float: left;width: 100%;height: calc(100vh - 150px);}
.finaltotoal_cart {float: left;width: 100%;position: absolute;padding: 15px 0px;left: 0;bottom: -15px;background: rgba(255, 255, 255, 0.7);backdrop-filter: blur(4px);display: flex;align-items: center;justify-content: flex-end;}
.supaTotalammount {float: right;width: auto;display: flex;align-items: center;}
.supaTotalammount h1 {font-size: 29px;font-weight: 700;margin: 0px;}
.supaTotalammount h4 {font-size: 18px;margin: 0px 12px 0px 0px;}
.checkoutbtn, .checkoutbtn:focus, .checkoutbtn:active {background-color: var(--loginColor) !important;border-color: var(--loginColor) !important;color: #fff !important;padding: 8px 20px;border-radius: 30px;font-size: 14px;margin: 0 0 0 15px;}
.checkoutbtn:hover {background-color: var(--maincolortwo) !important;}
ul.tablehead_tabs {float: left;width: 100%;display: flex;align-items: center;padding: 0px;border-bottom: 2px solid #2c59c0;}
ul.tablehead_tabs li {float: left;width: auto;list-style: none;margin-right: 10px;position: relative;padding: 8px 25px;cursor: pointer;line-height: normal;}
ul.tablehead_tabs li.active {background-color: #2c59c0;color: #fff;}
.view_details .form-group label {font-weight: 500;}
.view_details .form-group .text-show {float: left;width: 100%;background-color: #eee;padding: 10px 15px;font-size: 14px;}
.checkoutbtn img {width: 20px;margin: 1px 3px 0 0;float: left;}
.document_view {float: left;width: 100%;position: relative;}
.documnt_column {float: left;width: 100%;border-radius: 15px;display: flex;align-items: center;justify-content: flex-start;column-gap: 12px;padding: 10px 15px;border: 1px solid #eee;cursor: pointer;}
.documnt_column i {font-size: 30px;}
.documnt_column .file_details {float: left;width: auto;flex: 1;}
.documnt_column .file_details h4 {float: left;width: 100%;font-size: 17px;margin: 0 0 3px;}
.documnt_column .file_details span {float: left;width: 100%;font-size: 13px;color: #7e7c7c;}
.documnt_column:hover {border-color: #2c59c0;color: #2c59c0;} 
.download_file {border: 0px;background-color: rgb(30, 190, 44);padding: 5px 8px;line-height: normal;border-radius: 100%;color: #fff;}
.download_file i {font-size: 15px;}
.product_avatar {float: left;width: 100%;position: relative;}
.product_avatar .avatrshow {float: left;width: 100%;}
.product_avatar .avatrshow img {float: left;width: 100%;}
li.emptycart {font-size: 20px;font-weight: 600;display: flex;flex-direction: column;align-items: center;}
li.emptycart i {font-size: 45px;color: #ff200c !important;}
.uploadbtn {position: absolute;right: 0;color: #2c59c0;font-weight: 500;font-size: 14px;padding: 0px;}
.uploadbtn i {font-size: 16px;margin: 0px 1px 0px 0px;}
.form-sections {float: none;width: 100%;max-width: 650px;margin: 0 auto;display: table;}
.inputText {background-color: #f5f5f5;border: 1px solid #dee2e6;border-radius: 8px;box-shadow: none!important;cursor: no-drop;float: left;font-size: 13px;height: 40px;outline: none!important;padding: 8px 12px;width: 100%;}
.headecenter {float: left;width: 100%;font-size: 20px;margin: 20px 0 20px;padding: 10px 0 0;border-top: 1px solid #ddd;}
.errormendatorr {float: left;width: 100%;font-size: 13px;color: red;margin: 4px 0 0;}
.filedsearchReltive {float: left;width: 100%;position: relative;}
.filedsearchReltive .searchDropdownlist {max-height: 160px;}
.filedsearchReltive .searchWrapper {border: 1px solid #ccc;border-radius: 8px !important;max-height: 120px !important;position: relative;overflow: auto;padding: 8.1px 12px;font-size: 13px;}

/* pagination css start here */
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 9px 0px 0px;}
.paginationCustom li:first-child, .paginationCustom li:last-child {display: none;}
.paginationCustom li .rpb-item--active {background-color: var(--loginColor);}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;border-radius: 100%;}
/* pagination css end here */

.notformsection {float: left;width: 100%;text-align: center;}
.notformsection p {float: left;width: 100%;font-size: 17px;margin: 0 0 7px;}
.notformsection h5 {float: left;width: 100%;margin: 0px;}
.addbtncmn .spinner-border {width: 20px;height: 20px;margin: 1px 6px 0px 0px;float: left;}
.viewHeaderset {float: left;width: 100%;display: flex;justify-content: space-between;align-items: center;margin: 0 0 15px;}
.viewHeaderset h4 {float: left;width: auto;font-size: 18px;margin: 0px;flex: 1;}
.viewHeaderset .card-header-New {width: auto;margin: 0px;float: right;}
.viewHeaderset .header_back {width: auto;margin: 0px;border: 0px;}
.filedaddwraps.phoneListShow .form-group {margin-bottom: 0px;}
.formplusbtonset .addbtn {margin: 10px 0 0;}
.formplusbtonset .filedaddwraps.phoneListShow .addbtn {margin: 24px 0 0;}
/* file upload css start here */
.fileUploadingform input.form-control {line-height: 27px;border-radius: 8px;font-size: 13px;}
.filUploading {float: left;width: 100%;display: flex;align-items: center;font-size: 13px;justify-content: flex-start;column-gap: 8px;margin: 5px 0 0;}
.filUploading .spinner-border {width: 15px;height: 15px;border-width: 2px;}
.fileUploadingform input.form-control:disabled {cursor: not-allowed;}
.uploadedImagewrps {float: left;width: 140px;height: 140px;position: relative;}
.uploadedImagewrps .avatrshow {float: left;width: 100%;height: 100%;}
.uploadedImagewrps img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;border: 1px solid #dee2e6;border-radius: 8px;}
.uploadedImagewrps .closeIocn {float: left;width: auto;position: absolute;right: -7px;top: -10px;cursor: pointer;}
.uploadedImagewrps .closeIocn i {line-height: normal;background-color: #fff;color: #ff0808;}
/* file upload css end here */
.commonModal.addSupplierModel .modal-dialog {width: 100%;max-width: 95%;}
.commonModal.addSupplierModel .modal-dialog .header_back {display: none;}
.commonModal.addSupplierModel .modal-dialog .modal-footer {display: none;}
.column_count {float: left;width: 100%;display: flex;column-gap: 10px;}
/* InputFormGroup */
.form-size-1 {--fr-spacer-y: 0.45rem;--fr-spacer-x: 0.75rem;}
.form-size-2 {--fr-spacer-y: 0.55rem;--fr-spacer-x: 1rem;}
.form-size-3 {--fr-spacer-y: 1rem;--fr-spacer-x: 1rem;}
.form-size-4 {--fr-spacer-y: 0.75rem;--fr-spacer-x: 1rem;}
.form-size-5 {--fr-spacer-y: 0.75rem;--fr-spacer-x: 1rem;}
.form-size-6 {--fr-spacer-y: 0.75rem;--fr-spacer-x: 1rem;}
.form_group.radius-0,.rounded-0 {--bs-border-radius: 0px;--bs-btn-border-radius: 0px;}
.form_group.radius-1,.rounded-1 {--bs-border-radius: 0.375rem;--bs-btn-border-radius: 0.375rem;}
.form_group.radius-2,.rounded-2 {--bs-border-radius: 5px;--bs-btn-border-radius: 5px;}
.form_group.radius-3,.rounded-3 {--bs-border-radius: 7px;--bs-btn-border-radius: 7px;}
.form_group.radius-4,.rounded-4 {--bs-border-radius: 10px;--bs-btn-border-radius: 10px;}
.form_group.radius-5,.rounded-5 {--bs-border-radius: 100px;--bs-btn-border-radius: 100px;}
.form_group.radius-6,.rounded-6 {--bs-border-radius: 0px;--bs-btn-border-radius: 0px;}
body .form_group {
  --bs-body-font-size:14px;
  text-align: left;
  --text-color: var(--bs-body-color);
  --fr-transition: all 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  --fr-border-radius: var(--bs-border-radius);
  --fr-border-color: #cdd2da;
  --fr-box-shadow: none;
  --fr-border-focus-color: #86b7fe;
  --fr-danger-border-color: #dc3545;
  --fr-border-width: 1px;
  --fr-border-style: solid;
  --fr-color: var(--text-color);
  --fr-line-height: var(--bs-body-line-height);
  --fr-font-size: var(--bs-body-font-size);
  /* cursor: pointer; */
}
.cursor-pointer{cursor: pointer;}
body .form_group .form-control::placeholder,body .form_group .form-control {font-size: var(--fr-font-size)!important;}
body .form_group textarea.form-control {min-height: 134px;}
body .form_group textarea.form-control.min-h-auto {min-height: auto;}
body .form_group.form_style_underline .form-control {border-left-color: transparent !important;border-right-color: transparent !important;border-top-color: transparent !important;}
body .form_group .form-control.is-invalid:focus,.form_group .was-validated .form-control:invalid:focus {border-color: var(--fr-danger-border-color);box-shadow: none;}
body .form_group .form-control.is-invalid,.form_group .was-validated .form-control:invalid {border-color: var(--fr-danger-border-color);padding-right: calc(1.5em + var(--fr-spacer-x));background-repeat: no-repeat;background-position: right calc(0.375em + 0.1875rem) center;background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);}
body .form_group .form-control:focus,.form_group .form-select:focus {box-shadow: var(--fr-box-shadow);border-color: var(--fr-border-color);}
body .form_group .form-control {display: block;width: 100%;padding: var(--fr-spacer-y) var(--fr-spacer-x) !important;font-size: var(--fr-font-size);font-weight: 400;line-height: var(--fr-line-height);color: var(--fr-color);background-color: var(--bs-body-bg);background-clip: padding-box;border: var(--fr-border-width) var(--fr-border-style) var(--fr-border-color);appearance: none;border-radius: var(--fr-border-radius);transition: var(--fr-transition);margin: 0px 0px!important;}
body .form_group .form-control:focus,.form_group .form-select:focus {color: var(--fr-color);background-color: var(--bs-body-bg);border-color: var(--fr-border-focus-color);outline: 0;box-shadow: none;}
body .form_group .form-label {font-size: var(--fr-font-size);font-weight: 400;margin-bottom: 0.3rem;}
body .form_group .form-control {height: auto !important;}
body .form-validate {--fr-border-color: var(--fr-danger-border-color);}
body .form-validate .invalid-feedback {color: var(--fr-danger-border-color);--bs-font-size: 0.8rem;font-size: var(--bs-font-size);display: block;}
.form_group .form-select {display: block;width: 100%;padding: var(--fr-spacer-y) var(--fr-spacer-x);padding-right: 2rem;-moz-padding-start: auto;font-size: var(--fr-font-size);font-weight: 400;line-height: var(--fr-line-height);color: #212529;background-color: #fff;/* background-image: url(./../img/icon/arrowDown.svg); */background-repeat: no-repeat;background-position: right 0.75rem center;background-size: 16px 12px;border: var(--fr-border-width) var(--fr-border-style) var(--fr-border-color);border-radius: 0.375rem;transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
.input_group_icon .input-group {position: relative;display: flex;flex-wrap: nowrap;align-items: stretch;width: 100%;}
.input_group_icon .input-group-text {display: flex;align-items: center;padding: 0.375rem 0.75rem;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #212529;text-align: center;white-space: nowrap;background-color: var(--bs-body-bg);border: 1px solid var(--fr-border-color);border-radius: var(--fr-border-radius);user-select: none;transition: var(--fr-transition);position: relative;top: auto;left: auto;right: auto;}
.input_group_icon .input-group-text  i{position: relative!important;top: auto!important;left: auto!important;right: auto!important;}
.input_group_icon .input-group-text:first-child {border-right-color: transparent!important;border-right-width: 0px!important;}
.input_group_icon .input-group-text:last-child {border-left-color: transparent!important;border-left-width: 0px!important;}
.input_group_icon .input-group:focus-within .input-group-text {--fr-border-color: var(--fr-border-focus-color)!important;}
.input_group_icon.form-validate .input-group:focus-within .input-group-text{--fr-border-color:var(--fr-danger-border-color)!important}
.input_group_icon .form-control:first-child,.input_group_icon .form-control:first-child:focus {border-right-color: transparent!important;padding-right: 5px!important;border-top-right-radius: 0px!important;border-bottom-right-radius: 0px!important;border-right-width: 0px!important;}
.input_group_icon .form-control:last-child,.input_group_icon .form-control:last-child:focus {border-left-color: transparent!important;padding-left: 5px!important;border-top-left-radius: 0px!important;border-bottom-left-radius: 0px!important;border-left-width: 0px!important;}
.input_group_icon .form-control:not(:first-child):not(:last-child),.input_group_icon .form-control:not(:first-child):not(:last-child):focus {border-left-color: transparent!important;padding-left: 5px!important;border-top-left-radius: 0px!important;border-bottom-left-radius: 0px!important;border-left-width: 0px!important;}
.input_group_icon .form-control:not(:last-child),.input_group_icon .form-control:not(:last-child):focus {border-right-color: transparent!important;}
.green.table>:not(caption)>*>*{background-color: var(--dark_green);color: white;}
.cursor-pointer {cursor: pointer;}
/* Icon */
.common_icon{mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: var(--text-primary-color);height: 20px;width: 20px;display: inline-block;position: relative;--bg-icon:#000}
.common_icon.white{--bg-icon: #fff;}
.common_icon{background-color:var(--bg-icon);}
.common_icon.Mars{mask-image: url(./../img/icon/Mars.svg);-webkit-mask-image: url(./../img/icon/Mars.svg);}
.common_icon.VitalSigns{mask-image: url(./../img/icon/VitalSigns.svg);-webkit-mask-image: url(./../img/icon/VitalSigns.svg);}
.icon_button.btn-sm{
  --icon-button:38px;
  --bs-border-radius:var(--icon-button);
  --bs-btn-padding-x: 5px;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-padding-y: 5px;
  --font-size-icon:16px;
}
.icon_button{--icon-button:45px;--font-size-icon:20px;display: inline-flex!important;align-items: center;justify-content: center;border-color: transparent;background-color: transparent;height: var(--icon-button);width: var(--icon-button);--bs-border-radius:var(--icon-button);--bs-btn-padding-x: 10px;--bs-btn-border-radius: var(--bs-border-radius);--bs-btn-padding-y: 10px;padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x)!important;border-radius: var(--bs-btn-border-radius);}
.icon_button i{font-size: var(--font-size-icon);}
.icon_button:hover{background-color: #b6b7b62e;}
.icon_button:hover i{color: var(--loginColor);}
.icon_button .badge {border-radius: 100%;font-size: 11px;font-weight: 500;position: absolute;right: 8px;top: 3px;line-height: normal;background-color: #ff200c !important;box-sizing: border-box;}
.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: var(--bs-font-size);
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn.btn-primary{
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

body .nav-tabs {
  --bs-border-color:transparent;
  --bs-border-radius:4px;
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  --bs-nav-link-font-size:14px;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.dropdown-menu{
  --bs-border-color-translucent:transparent;
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: var(--bs-font-size);
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: var(--bs-body-color);
  --bs-dropdown-link-active-bg: rgb(var(--primary-rgb), 0.2);
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.patient-layout.layout-tabs{background-color: var(--mainBackground);height: 100%;width: 100%;position: relative;--bs-tab-padding-x:15px; --bs-tab-padding-y:15px}
.patient-layout.layout-tabs.p-0{--bs-tab-padding-x:0px; --bs-tab-padding-y:0px}
.patient-layout.layout-tabs .tab-content{background-color: white;padding:var(--bs-tab-padding-y) var(--bs-tab-padding-x);height: calc(100vh - 126px);width: 100%;}
.patient-layout.layout-tabs .tab-content .tab-pane {height: 100%;}
.patient-layout.layout-tabs .tab-content .all-patients{height: calc(100vh - 126px);--sidebar-width:290px}
.patient-layout.layout-tabs .tab-content .all-patients .d-flex.content-container.justify-content-start{height: calc(100% - 88px);overflow: hidden;}
.patient-layout.layout-tabs .tab-content .tab-content{height: calc(100vh - 260px);}
.patient-layout.layout-tabs .nav-tabs .nav-link::before{ content: ""; width: 12px; height: 12px; min-width: 12px; display: inline-block; margin-right: 10px; border-color: #a7aebf!important; border: 1px solid #dee2e6!important; border-radius: 50%!important; }
.patient-layout.layout-tabs .nav-tabs .nav-link.primary::before{    background-color: #2196f3!important;}
.patient-layout.layout-tabs .nav-tabs .nav-link.active{box-shadow: 0 -1px 5px -3px rgba(0,0,0,.75);}

.header-patient{padding: 0.25rem;display: flex;align-items: center;justify-content: space-between;--sidebar-width:290px}
.header-patient .d-flex{align-items: center;}
.header-patient .search-container { width: var(--sidebar-width); min-width: var(--sidebar-width); padding: 20px 17px; }
.header-patient .client-avatar{ width: 55px; height: 55px; margin-left: 20px; }
.header-patient .client-avatar img{height: 100%;width: 100%;}
.header-patient .client-content{padding-left: 15px;}
.header-patient .client-content .client-title{display: flex;font-size: 18px;font-weight: 600;    align-items: center;}
.header-patient .client-content .client-sub-title{display: flex;font-size: 13px;}
.header-patient .dropdown-options{padding-right: 20px;}
.status-badge{font-size: 10px;font-weight: 500;}
.status-badge.badge-warning {background-color: #ffa200;}
.all-patients .content-container .tab-sidebar{width: var(--sidebar-width);padding: 0px 0px 15px 0px;border-top: 1px solid #e8eaee;}
.all-patients .content-container .tab-sidebar .view{padding-top: 10px;}
.all-patients .content-container .patient-details{width: calc(100%  - var(--sidebar-width));border-top: 1px solid #e8eaee;border-left: 1px solid #e8eaee;padding: 7px 0px 0px 7px;background-color: var(--mainBackground);}

/* aniket css added here */
.patient-layout-new {
  float: left;
  width: 100%;
  padding: 15px 5px;
  position: relative;
}
.selectNoInputField .inputBoxSelect .selectEmojis::after {
  display: none;
}
.fileUploadingform input.form-control {
  border-radius: 8px;
  font-size: 13px;
  line-height: 27px;
}
.gendor_wrapper {
  float: left;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
}
.gendor_wrapper label {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.gendor_wrapper label input[type="radio"] {
  height: 16px;
  width: 16px;
  margin: 0 5px 0px 0px;
}
.form_header {
  float: left;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 0px 10px 11px;
  margin: 15px 0 20px;
  font-size: 18px;
  position: relative;
}
.flex-column-tab-set .nav-link {
  padding: 10px 15px;
  cursor: pointer;
}
.flex-column-tab-set {
  padding: 10px;
}
.flex-column-tab-set .nav-link.active i {
  color: #fff;
  --bg-icon: #fff;
}
.flex-column-tab-set .nav-link.active span{
  color: #fff;
}
.patient-details-tabss .tab-content .tab-pane {
  padding: 20px;
}
.userdropdown {
  float: left;
  width: auto;
  }
  .userdropdown .btn, .userdropdown .btn:focus {
  padding: 0px;
  background: transparent;
  border: 0px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .userdropdown .btn img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin: 0 8px 0px 0px;
  }
  .toglebutton {
  width: 21.63px;
  float: left;
  height: 21.63px;
  padding: 0px;
  border: 0px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .toglebutton img {
  width: 100% !important;
  height: 100% !important;
  }
/* aniket css added here */